.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.box {
  max-width: 340px;
  margin: 50px auto;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.box__logo {
  margin-bottom: 1em;
}
.box .title {
  font-size: 33px;
  margin-bottom: 30px;
}
.box .error {
  color: red;
  display: flex;
  justify-content: center;
}
.box form {
  width: 100%;
}
.box form div {
  width: 100%;
  margin-bottom: 30px;
}
.box form button {
  background: linear-gradient(45deg, #2196f3 30%, #21cbf3 90%);
  border: 0;
  border-radius: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: white;
  padding: 10px 0;
  text-transform: uppercase;
}
.box form button:hover {
  background: #fff;
  border: 1px solid #99a2ae;
  color: #3f4142;
  cursor: pointer;
}
.box form button:focus {
  outline: 0;
}
.box input {
  border: 0;
  box-shadow: 0 1px 0 0;
  width: 100%;
  background: rgba(76, 112, 239, 0);
}
.box input:focus {
  box-shadow: 0 1px 0 0 blue;
  outline: 0;
}
.links {
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: transparent;
}
.links a {
  font-size: 12px;
  color: #393e44;
  letter-spacing: 0;
  text-align: center;
  text-decoration: underline;
}
.links a:hover {
  text-decoration: none;
}
